<template>
  <div class="grid grid-cols-6 gap-2 place-content-start">
    <div class="flex flex-col">
      <span class="font-bold">Tipo de egreso:</span>
      <Dropdown v-model="expenseType" :options="typesExpenses" optionLabel="name" optionValue="value" placeholder="Seleccione una opcion"/>
    </div>
    <div class="flex items-end justify-center">
      <Button icon="pi pi-search"
              class="mr-2 p-button-info"
              @click="search"
              v-tooltip.top="'Buscar'"
      />
      <Button label="Limpiar filtro"
              class="mr-2 p-button-info"
              @click="clearFilter"
      />
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Egresos:</span>
      <span>{{ $h.formatCurrency(storeTotalExp,2) }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Registros:</span>
      <span>{{ storeExpenses.count }}</span>
    </div>
    <div class="flex items-end justify-center">
      <Button label="Agregar Egreso"
              icon="pi pi-plus"
              class="mr-2"
              @click="addExpense"
      />
    </div>
  </div>
</template>

<script>
import useExpense from '../../../hooks/useExpense'
import ExpenseService from '../../../services/expenseServices'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { error } from '@/libs/mensajes'

export default {
  name: 'homeTabsIngresosHeader',
  setup () {
    const store = useStore()
    const _expenseService = new ExpenseService()
    const { typesExpenses, getExpenses } = useExpense()
    const numberDoc = ref(null)
    const expenseType = ref(null)
    const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])
    const storeTotalExp = computed(() => store.getters['storePettyCash/getTotalExp'])
    const storeExpenses = computed(() => store.getters['storePettyCash/getExpenses'])
    const route = useRoute()
    const router = useRouter()

    const search = async () => {
      await getExpenses({ date: storeDate.value, expenseType: expenseType.value })
      // await getIncomes({ date: storeDate.value, expenseType: expenseType.value, numberDoc: numberDoc.value })
      console.log(route)
    }

    const clearFilter = () => {
      if (expenseType.value !== null) {
        expenseType.value = null
        // getIncomes({ date: storeDate.value, expenseType: expenseType.value, numberDoc: numberDoc.value })
      }
    }

    const addExpense = async () => {
      const validate = await _expenseService.expenseValidate()
      if (!validate.status || storeDate.value === dayjs().format('YYYY-MM-DD')) {
        await router.push({ name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.crear' })
      } else {
        error(`La caja del dia ${ validate.date } no se ha cerrado`)
      }
    }

    return {
      numberDoc,
      expenseType,
      typesExpenses,
      storeTotalExp,
      storeExpenses,
      search,
      clearFilter,
      addExpense
    }
  }
}
</script>

<style scoped>

</style>
