<template>
  <Header/>
  <Table/>
</template>

<script>
import { defineComponent } from 'vue'
import iconEgreso from '@/assets/images/icon_egreso.svg'

import Header from '../tabEgresos/header'
import Table from '../tabEgresos/table'

export default defineComponent({
  components: {
    Header,
    Table
  },
  setup () {
    return {
      iconEgreso
    }
  }
})
</script>
